import { productsForDatalayer } from '~/utils/productsForDatalayer'

const getFinalPrice = (event, price, isMediclubMember) => {
  let currency
  let value

  if (event !== 'remove_from_cart') {
    const regularPrice = price.regular?.value || ''
    const mediclubPrice = (!price.mediclub ? regularPrice : price.mediclub?.value) || ''

    value = (isMediclubMember ? mediclubPrice : regularPrice) || ''
    currency = price.regular?.currency || ''
  } else {
    value = (!!price.value || (isMediclubMember ? price.mediclub?.value : price.regular?.value)) || ''
    currency = (!!price.currency || price.regular?.currency) || ''
  }

  return {
    currency,
    value,
  }
}

const trackProductOnCartEvent = (
  event,
  contactId = '',
  product,
  quantity,
  price = {},
  isMediclubMember = false,
  reserveData = {},
  facility = {},
  uid = '',
  vendor = '') => {
  const finalPrice = getFinalPrice(event, price, isMediclubMember)

  const items = productsForDatalayer([product], {
    price: finalPrice.value,
    currency: finalPrice.currency,
    quantity,
  })

  return {
    event,
    ecommerce: {
      contactId,
      currency: finalPrice.currency,
      value: finalPrice.value,
      ...(event === 'impressions'
        ? {
            impressions: items,
          }
        : { items }),
    },
  }
}

export default trackProductOnCartEvent
