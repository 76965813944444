import trackProductOnCartEvent from '~/helpers/gtm/events/trackProductOnCartEvent'
import trackProductSelectFromList from '~/helpers/gtm/events/trackProductSelectFromList'
import trackCheckoutBegin from '~/helpers/gtm/events/trackCheckoutBegin'
import trackPaymentInfo from '~/helpers/gtm/events/trackPaymentInfo'
import trackShippingInfo from '~/helpers/gtm/events/trackShippingInfo'
import trackPurchase from '~/helpers/gtm/events/trackPurchase'

export default {
  trackProductOnCartEvent,
  trackProductSelectFromList,
  trackCheckoutBegin,
  trackPaymentInfo,
  trackShippingInfo,
  trackPurchase,
}
