import { productsForDatalayer } from '~/utils/productsForDatalayer'

const trackPurchase = (
  event = '',
  gtmData = {},
  contactId = '',
  vendor = '',
) => {
  const { grandTotal, subtotalIncludingTax, subtotalExcludingTax } = gtmData.prices
  const coupon = gtmData.coupons?.map(o => o.code)
  const {
    transactionId = '',
    products = {},
    isLogged = false,
    paymentMethods = {},
    isRegisteredOnCheckout = false,
    finalShippingPrice = '',
    isForeigner = false,
    buyingFor = 'myself',
  } = gtmData

  return {
    event: 'purchase',
    ecommerce: {
      contactId,
      transaction_id: transactionId || '',
      affiliation: 'Google Merchandise Store',
      tax: (+subtotalIncludingTax.value - +subtotalExcludingTax.value).toFixed(2) || '',
      shipping: Number(finalShippingPrice) || '',
      currency: grandTotal.currency || '',
      value: Number(grandTotal.value) || '',
      coupon,
      payment_type: paymentMethods,
      logged: isLogged ? 'YES' : 'NO',
      open_account: isRegisteredOnCheckout ? 'YES' : 'NO',
      items: productsForDatalayer(products, { coupon }, Number(finalShippingPrice)),
      buying_for: buyingFor,
      foreigner: isForeigner ? 'YES' : 'NO',
    },
  }
}

export default trackPurchase
