import { productsForDatalayer } from '~/utils/productsForDatalayer'

const trackShippingInfo = (
  event = '',
  gtmData,
  contactId = '',
  vendor = '',
) => {
  const { grandTotal = {} } = gtmData.prices
  const coupon = gtmData.coupons?.map(o => o.code)
  const {
    products = {},
    paymentMethods = {},
    isRegisteredOnCheckout = false,
    finalShippingPrice = '',
    isLogged = false,
  } = gtmData

  return event === 'add_shipping_info'
    ? {
        event,
        ecommerce: {
          contactId,
          currency: grandTotal?.currency || '',
          value: grandTotal?.value || '',
          coupon,
          payment_type: paymentMethods,
          logged: isLogged ? 'YES' : 'NO',
          open_account: isRegisteredOnCheckout ? 'YES' : 'NO',
          items: productsForDatalayer(products, { coupon }, Number(finalShippingPrice)),
        },
      }
    : {
        event,
        ecommerce: {
          contactId,
          checkout_option: {
            actionField: { step: 3 },
          },
          items: productsForDatalayer(products, { coupon }, Number(finalShippingPrice)),
        },
      }
}

export default trackShippingInfo
