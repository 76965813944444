import { productsForDatalayer } from '~/utils/productsForDatalayer'

const trackProductSelectFromList = (event, product, price, contactId, vendor = '') => {
  const items = productsForDatalayer([product])

  return event === 'select_item'
    ? {
        event,
        ecommerce: {
          items,
          contactId,
        },
      }
    : {
        event,
        ecommerce: {
          click: {
            actionField: { list: '' },
            products: items,
            contactId,
          },
        },
      }
}

export default trackProductSelectFromList
