import { productsForDatalayer } from '~/utils/productsForDatalayer'

const trackCheckoutBegin = (
  event = '',
  gtmData,
  vendor = '',
  contactId = '',
) => {
  const { grandTotal } = gtmData.prices
  const coupon = gtmData.coupons?.map(o => o.code)
  const { products, finalShippingPrice } = gtmData

  return {
    event,
    ecommerce: {
      currency: grandTotal.currency || 'no data',
      value: Number(grandTotal.value) || 'no data',
      coupon: gtmData.coupons?.map(o => o.code),
      items: productsForDatalayer(products, { coupon }, Number(finalShippingPrice)),
      contactId,
    },
  }
}

export default trackCheckoutBegin
