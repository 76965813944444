import { isPhysicalProduct } from '@strix/checkout/helpers/productsType'

export const productsForDatalayer = (items, supplements = {}, finalShippingPrice = 0) => {
  return items.reduce((acc, item) => {
    if (Array.isArray(item)) {
      const groupedProducts = productsForDatalayer(item, supplements, finalShippingPrice)

      return acc.concat(groupedProducts)
    }

    // hasOwn nie dziala na FF91!
    const product = Object.prototype.hasOwnProperty.call(item, 'product') ? item.product : item // product or cartProduct?
    const price = Object.prototype.hasOwnProperty.call(item, 'prices') ? item?.prices?.price : product?.priceRange?.minimumPrice

    let facility

    if (item.customizableOptions) {
      const { customizableOptions: [{ values: [{ customizableOptionValueUid: itemUid }] }] } = item

      facility = item.product
        ?.optionsByCities
        ?.flatMap(({
          facilities,
          ...city
        }) => facilities.map(facility => ({
          ...facility,
          city,
        })))
        ?.find(({ options }) => options.map(({ uid }) => uid).includes(itemUid))
    }

    const {
      adultsLimit,
      name,
      popular,
      urlKey,
      categories,
      specialty,
      sku,
      siteSku,
      breadcrumbs = [],
      pimBrand,
    } = product

    const ageFrom = item.beneficiaryAgeFrom || product.ageFrom
    const ageTo = item.beneficiaryAgeTo || product.ageTo

    acc.push({
      ...(sku ? { item_id: sku } : {}),
      ...(siteSku ? { site_sku: siteSku } : {}),
      ...(name ? { item_name: name } : {}),
      ...(price
        ? {
            price: price.value,
            currency: price.currency,
          }
        : {}),
      ...(breadcrumbs[0] ? { item_category: breadcrumbs[0].categoryName } : {}),
      ...(breadcrumbs[1] ? { item_category2: breadcrumbs[1].categoryName } : {}),
      ...(breadcrumbs[2] ? { item_category3: breadcrumbs[2].categoryName } : {}),
      ...(breadcrumbs[3] ? { item_category4: breadcrumbs[3].categoryName } : {}),
      ...(breadcrumbs[4] ? { item_category5: breadcrumbs[4].categoryName } : {}),
      ...(item?.vendor ? { item_brand: pimBrand } : {}),
      ...(item?.id ? { index: item.id } : {}),
      ...(item?.quantity ? { quantity: item.quantity } : {}),
      ...(item?.prices ? { discount: item.prices.totalItemDiscount.value } : {}),

      // additional fields
      ...(adultsLimit ? { adultsLimit } : {}),
      ...(ageFrom ? { ageFrom } : {}),
      ...(ageTo ? { ageTo } : {}),
      ...(popular ? { popular } : {}),
      ...(urlKey ? { urlKey } : {}),
      ...(categories ? { categories } : {}),
      ...(specialty?.length ? { specialty } : {}),
      ...(item?.reserveData?.appointmentsDate ? { time_id: item.reserveData?.appointmentsDate } : {}),

      ...(facility
        ? {
            location: facility?.city.name,
            location1: facility?.nameOfFacility,
          }
        : {}),
      ...(isPhysicalProduct(item._typename) ? { shipping: finalShippingPrice } : {}),
      ...supplements,
    })

    return acc
  }, [])
}
