
import { createNamespacedHelpers } from 'vuex'
import userTypes from '~/store/user/types'

const {
  mapGetters: mapGettersUser,
} = createNamespacedHelpers('user')

export default {
  name: 'HeaderMinicartItemGrouped',

  props: {
    item: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGettersUser({
      isMediclubMember: userTypes.GET_IS_MEDICLUB_MEMBER,
    }),

    mainProduct () {
      return this.item[0].product
    },

    details () {
      return this.item.reduce((acc, item) => {
        const itemCopy = JSON.parse(JSON.stringify(item))
        const label = itemCopy.customizableOptions[0].values[0].label
        const nomediclubPrice = itemCopy.nomediclubPrices?.rowTotalIncludingTaxWithDiscount
        const mediclubPrice = itemCopy.mediclubPrice?.rowTotalIncludingTaxWithDiscount

        acc.push({
          label,
          nomediclubPrice,
          mediclubPrice,
        })

        return acc
      }, [])
    },

    getTotalPrice () {
      const totalPrice = this.item.reduce((acc, item) => {
        acc += Number(item.prices?.rowTotalIncludingTax.value)
        acc -= Number(item.prices?.totalItemDiscount.value) // liczenie ceny
        return acc
      }, 0)

      return {
        value: totalPrice,
        currency: this.item[0].prices.price.currency,
      }
    },

    UidsToRemove () {
      return this.item.reduce((acc, value) => {
        acc.push({
          cart_item_uid: value.uid,
        })
        return acc
      }, [])
    },
  },
}
