import { productsForDatalayer } from '~/utils/productsForDatalayer'

const trackPaymentInfo = (
  event = '',
  products = {},
  prices = {},
  coupons = {},
  paymentMethods = {},
  finalShippingPrice = '',
  contactId = '',
) => {
  const { grandTotal } = prices
  const coupon = coupons?.map(o => o.code)

  return {
    event,
    ecommerce: {
      currency: grandTotal.currency,
      value: grandTotal.value,
      coupon: coupons?.map(o => o.code),
      payment_type: paymentMethods,
      items: productsForDatalayer(products, { coupon }, Number(finalShippingPrice)),
      contactId,
    },
  }
}

export default trackPaymentInfo
